// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type RoundAlertIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let RoundAlertIcon: NessieFunctionComponent<RoundAlertIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "RoundAlertIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: RoundAlertIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.92462 4.92462C8.86432 1.02513 15.1759 1.02513 19.0754 4.92462C22.9749 8.82412 22.9749 15.1357 19.0754 19.0754C15.1357 22.9749 8.82412 22.9749 4.92462 19.0754C1.02513 15.1357 1.02513 8.82412 4.92462 4.92462ZM12.028 6C12.7482 6 13.2927 6.56207 13.2927 7.28222C13.2927 7.51908 13.2129 9.21714 13.1339 10.8959C13.0958 11.7071 13.0578 12.5137 13.0292 13.1488C13.0117 13.6933 12.5726 14.1149 11.9929 14.1149C11.4484 14.1149 11.0093 13.6933 10.9917 13.1488C10.9536 12.3027 10.8957 11.1523 10.8423 10.0923C10.7726 8.70973 10.7107 7.48106 10.7107 7.28222C10.7107 6.56207 11.2728 6 12.028 6ZM12.0105 15.1688C12.7833 15.1688 13.4332 15.8187 13.4332 16.5915C13.4332 17.3468 12.7833 17.9967 12.0105 17.9967C11.2376 17.9967 10.6053 17.3468 10.6053 16.5915C10.6053 15.8187 11.2376 15.1688 12.0105 15.1688Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
RoundAlertIcon.nessiePropTypes = iconNessiePropTypes;
RoundAlertIcon = nessiePropCheckerWrapper(RoundAlertIcon);
export default RoundAlertIcon;
