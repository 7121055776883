import React from "react";
import { UnstyledButton } from "../..";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { CheckIcon, CloseIcon, MinusIcon } from "../icons";
import { Button } from "./Button";
import { translate } from "../../utils/translate";

type ToastBannerProps = {
  text: string | React.ReactElement;
  success?: boolean;
  error?: boolean;
  leftIcon?: React.ReactElement;
  rightButton?: {
    text: string;
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name": string;
    onClick: (arg?: unknown) => void;
  };
  closeToast?: () => void;
  transparent?: boolean;
};

function ToastBanner({ text, error, success, leftIcon, rightButton, closeToast, transparent }: ToastBannerProps) {
  const errorStyles: ThemeUIStyleObject = error ? { backgroundColor: "dt_content_danger" } : {};
  const successStyles: ThemeUIStyleObject = success ? { backgroundColor: "dt_content_success" } : {};
  return (
    <div sx={{ ...toastContainerStyles, ...successStyles, ...errorStyles, opacity: transparent ? 0 : 1 }}>
      {error ? (
        <MinusIcon color="dt_content_light" />
      ) : (
        leftIcon || (success ? <CheckIcon color="dt_content_light" /> : null)
      )}
      <span sx={{ flexGrow: 1 }}>{text}</span>
      {rightButton && !transparent ? (
        <Button onClick={rightButton.onClick} size="s" data-name={rightButton["data-name"]}>
          {rightButton.text}
        </Button>
      ) : null}
      <UnstyledButton
        sx={closeButtonStyles}
        onClick={closeToast}
        data-name="toastCloseButton"
        aria-label={translate({ str: "dojo.common:actions.close", fallback: "Close" })}
      >
        <CloseIcon color="dt_content_light" />
      </UnstyledButton>
    </div>
  );
}

export default ToastBanner;

const toastContainerStyles: ThemeUIStyleObject = {
  paddingY: "dt_s",
  paddingX: "dt_m",
  minHeight: 60,
  minWidth: 350,
  backgroundColor: "dt_content_primary",
  color: "dt_content_light",
  borderRadius: "dt_radius_s",
  display: "flex",
  alignItems: "center",
  fontWeight: 600,
  gap: "dt_m",
  fontSize: 15,
};

const closeButtonStyles: ThemeUIStyleObject = {
  marginLeft: "auto",
  width: 40,
  height: 40,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
