// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type AddIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let AddIcon: NessieFunctionComponent<AddIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "AddIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: AddIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 10H21C22.1046 10 23 10.8954 23 12C23 13.1046 22.1046 14 21 14H14V21C14 22.1046 13.1046 23 12 23C10.8954 23 10 22.1046 10 21V14H3C1.89543 14 1 13.1046 1 12C1 10.8954 1.89543 10 3 10H10V3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3V10Z"
          />
          <mask id="AddIcon_svg__mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x={1} y={1} width={22} height={22}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14 10H21C22.1046 10 23 10.8954 23 12C23 13.1046 22.1046 14 21 14H14V21C14 22.1046 13.1046 23 12 23C10.8954 23 10 22.1046 10 21V14H3C1.89543 14 1 13.1046 1 12C1 10.8954 1.89543 10 3 10H10V3C10 1.89543 10.8954 1 12 1C13.1046 1 14 1.89543 14 3V10Z"
            />
          </mask>
          <g mask="url(#AddIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
AddIcon.nessiePropTypes = iconNessiePropTypes;
AddIcon = nessiePropCheckerWrapper(AddIcon);
export default AddIcon;
