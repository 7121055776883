import * as React from "react";
import { NumberBadge, LabelText } from "../../nessie";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import UnstyledButton from "./UnstyledButton";

type NavbarButtonProps = {
  disabled?: boolean;
  href?: string;
  target?: string;
  id?: string;
  className?: string;
  onClick?: React.ReactEventHandler;
  active?: boolean;
  light?: boolean;
  opensModal?: boolean;
  icon?: React.ReactElement;
  badge?: number;
  badgeMax?: number;
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name": string;
  as?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "li" | "label";
  // necessary for a11y
  notToggle?: boolean;
  text: string;
};

/**
 * Button for our navigation bars
 */
const NavbarButton = (props: NavbarButtonProps): JSX.Element => {
  const {
    disabled,
    href,
    target,
    id,
    className,
    onClick,
    active,
    light,
    icon,
    badge = 0,
    badgeMax,
    notToggle,
    opensModal,
    as,
    text,
    ["data-name"]: dataName,
  } = props;
  const Component = href ? "a" : UnstyledButton;

  return (
    <Component
      aria-current={href ? (active ? true : false) : undefined}
      aria-pressed={href || notToggle || opensModal ? undefined : active ? true : false}
      aria-haspopup={(!href || !notToggle) && opensModal ? true : undefined}
      sx={{ ...componentStyles, fontWeight: light ? 400 : 600 }}
      disabled={disabled && !active}
      target={target}
      onClick={onClick}
      href={href}
      className={className}
      id={id}
      aria-label={badge ? `${text} (${badge})` : text}
      data-name={dataName}
    >
      {icon}

      <LabelText level={2} as={as} sx={{ flex: "0 0 auto", color: "inherit" }}>
        {text}
      </LabelText>

      {badge ? <NumberBadge value={badge} max={badgeMax} kind="danger" size="xs" data-name="badge" /> : null}
    </Component>
  );
};

export default NavbarButton;

const componentStyles: ThemeUIStyleObject = {
  fontSize: "1.6rem",
  fontWeight: 600,
  display: "flex",
  alignItems: "center",
  gap: "dt_xs",
  flexWrap: "nowrap",
  justifyContent: "center",
  paddingX: ["dt_s", "dt_s", "dt_s", "dt_m", "dt_l"],
  color: "dt_content_secondary",
  paddingY: "dt_s",
  width: "max-content",
  transition: "padding 0.3s ease-out, margin 0.3s ease-out",
  borderBottom: RAW_cssValue("2px solid transparent"),
  "&:disabled, &[disabled]": {
    color: "dt_content_secondary",
    pointerEvents: "none",
  },
  "&:hover": {
    color: "dt_content_secondary",
    backgroundColor: "dt_background_secondary",
    borderBottom: "dt_active",
  },
  '&[aria-pressed="true"], &[aria-current="true"]': {
    color: "dt_content_accent",
    borderBottom: "dt_active",
    paddingX: "0",
    marginX: ["dt_s", "dt_s", "dt_s", "dt_m", "dt_l"],

    "&:hover": { backgroundColor: "transparent" },
  },
};
