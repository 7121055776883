// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type XCircleIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let XCircleIcon: NessieFunctionComponent<XCircleIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "XCircleIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: XCircleIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.0754 4.92462C15.1759 1.02513 8.86432 1.02513 4.92462 4.92462C1.02513 8.82412 1.02513 15.1357 4.92462 19.0754C8.82412 22.9749 15.1357 22.9749 19.0754 19.0754C22.9749 15.1357 22.9749 8.82412 19.0754 4.92462ZM15.9799 15.9799C15.5377 16.4221 14.7739 16.4221 14.3317 15.9799L12 13.6482L9.66834 15.9799C9.22613 16.4221 8.46231 16.4221 8.0201 15.9799C7.57789 15.5377 7.57789 14.7739 8.0201 14.3317L10.3518 12L8.0201 9.66834C7.57789 9.22613 7.57789 8.46231 8.0201 8.0201C8.46231 7.57789 9.22613 7.57789 9.66834 8.0201L12 10.3518L14.3317 8.0201C14.7739 7.57789 15.5377 7.57789 15.9799 8.0201C16.4221 8.46231 16.4221 9.22613 15.9799 9.66834L13.6482 12L15.9799 14.3317C16.4623 14.7739 16.4623 15.5377 15.9799 15.9799Z"
          />
          <mask
            id="XCircleIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={2}
            y={2}
            width={20}
            height={20}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.0754 4.92462C15.1759 1.02513 8.86432 1.02513 4.92462 4.92462C1.02513 8.82412 1.02513 15.1357 4.92462 19.0754C8.82412 22.9749 15.1357 22.9749 19.0754 19.0754C22.9749 15.1357 22.9749 8.82412 19.0754 4.92462ZM15.9799 15.9799C15.5377 16.4221 14.7739 16.4221 14.3317 15.9799L12 13.6482L9.66834 15.9799C9.22613 16.4221 8.46231 16.4221 8.0201 15.9799C7.57789 15.5377 7.57789 14.7739 8.0201 14.3317L10.3518 12L8.0201 9.66834C7.57789 9.22613 7.57789 8.46231 8.0201 8.0201C8.46231 7.57789 9.22613 7.57789 9.66834 8.0201L12 10.3518L14.3317 8.0201C14.7739 7.57789 15.5377 7.57789 15.9799 8.0201C16.4221 8.46231 16.4221 9.22613 15.9799 9.66834L13.6482 12L15.9799 14.3317C16.4623 14.7739 16.4623 15.5377 15.9799 15.9799Z"
            />
          </mask>
          <g mask="url(#XCircleIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
XCircleIcon.nessiePropTypes = iconNessiePropTypes;
XCircleIcon = nessiePropCheckerWrapper(XCircleIcon);
export default XCircleIcon;
