// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type CameraIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let CameraIcon: NessieFunctionComponent<CameraIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "CameraIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: CameraIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M19.8954 7.17447C21.3636 7.17447 21.8397 8.44421 21.8794 9.11876V17.3324C21.8794 18.7609 20.6097 19.237 19.9351 19.3164H19.8954H4.0633C3.42842 19.3164 2.91259 19.118 2.55547 18.7212C2.06877 18.1971 2.07661 17.5316 2.07905 17.3248L2.07932 17.2927V9.35684C2.07932 7.72998 3.4681 7.25382 4.22201 7.21414L7.51542 7.17447C7.67413 4.35722 9.18196 4.11914 9.34067 4.11914H9.38035H14.7768C16.4433 4.11914 17.0385 5.86504 17.1575 7.17447H19.8954ZM7.23766 13.0867C7.23766 15.6262 9.30099 17.7292 11.8802 17.7292C14.4593 17.7292 16.5227 15.6262 16.5227 13.0867C16.5227 10.5472 14.4593 8.44421 11.8802 8.44421C9.34067 8.44421 7.23766 10.5075 7.23766 13.0867ZM18.3479 11.3408C17.8718 11.3408 17.475 10.944 17.475 10.4679C17.475 9.99171 17.8718 9.59492 18.3479 9.59492C18.8241 9.59492 19.2209 9.99171 19.2209 10.4679C19.2209 10.944 18.8241 11.3408 18.3479 11.3408ZM8.82484 13.047C8.82484 11.3805 10.1739 9.99171 11.8802 9.99171C13.5864 9.99171 14.9355 11.3805 14.9355 13.047C14.9355 14.7136 13.5864 16.1024 11.8802 16.1024C10.2136 16.1024 8.82484 14.7533 8.82484 13.047Z"
          />
          <mask
            id="CameraIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={2}
            y={4}
            width={20}
            height={16}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M19.8954 7.17447C21.3636 7.17447 21.8397 8.44421 21.8794 9.11876V17.3324C21.8794 18.7609 20.6097 19.237 19.9351 19.3164H19.8954H4.0633C3.42842 19.3164 2.91259 19.118 2.55547 18.7212C2.06877 18.1971 2.07661 17.5316 2.07905 17.3248L2.07932 17.2927V9.35684C2.07932 7.72998 3.4681 7.25382 4.22201 7.21414L7.51542 7.17447C7.67413 4.35722 9.18196 4.11914 9.34067 4.11914H9.38035H14.7768C16.4433 4.11914 17.0385 5.86504 17.1575 7.17447H19.8954ZM7.23766 13.0867C7.23766 15.6262 9.30099 17.7292 11.8802 17.7292C14.4593 17.7292 16.5227 15.6262 16.5227 13.0867C16.5227 10.5472 14.4593 8.44421 11.8802 8.44421C9.34067 8.44421 7.23766 10.5075 7.23766 13.0867ZM18.3479 11.3408C17.8718 11.3408 17.475 10.944 17.475 10.4679C17.475 9.99171 17.8718 9.59492 18.3479 9.59492C18.8241 9.59492 19.2209 9.99171 19.2209 10.4679C19.2209 10.944 18.8241 11.3408 18.3479 11.3408ZM8.82484 13.047C8.82484 11.3805 10.1739 9.99171 11.8802 9.99171C13.5864 9.99171 14.9355 11.3805 14.9355 13.047C14.9355 14.7136 13.5864 16.1024 11.8802 16.1024C10.2136 16.1024 8.82484 14.7533 8.82484 13.047Z"
            />
          </mask>
          <g mask="url(#CameraIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
CameraIcon.nessiePropTypes = iconNessiePropTypes;
CameraIcon = nessiePropCheckerWrapper(CameraIcon);
export default CameraIcon;
