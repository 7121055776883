// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type StoryIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let StoryIcon: NessieFunctionComponent<StoryIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "StoryIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: StoryIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.10388 3C1.49422 3 1 3.49422 1 4.10388V17.2789C1 17.8885 1.49422 18.3827 2.10388 18.3827H14.0205L12.6149 16.7346H2.66198V4.64815H20.3897V9.34564C20.5552 9.32729 20.7223 9.31791 20.8904 9.31791C21.2922 9.31791 21.6815 9.37122 22.0517 9.47108V4.10388C22.0517 3.49422 21.5575 3 20.9478 3H2.10388ZM13.0227 7.94445L14.2173 9.61469C12.5665 10.2517 11.3945 11.8441 11.3945 13.7042C11.3945 14.1716 11.468 14.6295 11.6108 15.0645H3.76996C4.63314 11.4754 9.87646 12.7316 9.87646 12.7316L13.0227 7.94445ZM17.2516 11.1045C17.2849 11.061 17.3191 11.0183 17.3539 10.9762L17.398 11.1045L17.2516 11.1045ZM7.10786 10.1368C8.03333 10.1368 8.7828 9.39897 8.7828 8.49125C8.7828 7.58255 8.03333 6.84568 7.10786 6.84568C6.1824 6.84568 5.43194 7.58255 5.43194 8.49125C5.43194 9.39897 6.1824 10.1368 7.10786 10.1368ZM18.4241 11.9363C17.844 11.1798 16.8984 10.6914 15.8884 10.6914C14.2126 10.6914 12.8498 12.0434 12.8498 13.7042C12.8498 14.358 13.057 14.9804 13.4498 15.5029C13.4559 15.5111 17.9859 20.8231 17.9859 20.8231C18.0806 20.9346 18.2196 20.9989 18.367 21C18.5155 21 18.654 20.9374 18.7498 20.828L23.292 15.6375C23.7485 15.0969 24 14.4101 24 13.7042C24 12.0434 22.6361 10.6914 20.9608 10.6914C19.9503 10.6914 19.0047 11.1798 18.4241 11.9363Z"
          />
          <mask
            id="StoryIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={1}
            y={3}
            width={23}
            height={18}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.10388 3C1.49422 3 1 3.49422 1 4.10388V17.2789C1 17.8885 1.49422 18.3827 2.10388 18.3827H14.0205L12.6149 16.7346H2.66198V4.64815H20.3897V9.34564C20.5552 9.32729 20.7223 9.31791 20.8904 9.31791C21.2922 9.31791 21.6815 9.37122 22.0517 9.47108V4.10388C22.0517 3.49422 21.5575 3 20.9478 3H2.10388ZM13.0227 7.94445L14.2173 9.61469C12.5665 10.2517 11.3945 11.8441 11.3945 13.7042C11.3945 14.1716 11.468 14.6295 11.6108 15.0645H3.76996C4.63314 11.4754 9.87646 12.7316 9.87646 12.7316L13.0227 7.94445ZM17.2516 11.1045C17.2849 11.061 17.3191 11.0183 17.3539 10.9762L17.398 11.1045L17.2516 11.1045ZM7.10786 10.1368C8.03333 10.1368 8.7828 9.39897 8.7828 8.49125C8.7828 7.58255 8.03333 6.84568 7.10786 6.84568C6.1824 6.84568 5.43194 7.58255 5.43194 8.49125C5.43194 9.39897 6.1824 10.1368 7.10786 10.1368ZM18.4241 11.9363C17.844 11.1798 16.8984 10.6914 15.8884 10.6914C14.2126 10.6914 12.8498 12.0434 12.8498 13.7042C12.8498 14.358 13.057 14.9804 13.4498 15.5029C13.4559 15.5111 17.9859 20.8231 17.9859 20.8231C18.0806 20.9346 18.2196 20.9989 18.367 21C18.5155 21 18.654 20.9374 18.7498 20.828L23.292 15.6375C23.7485 15.0969 24 14.4101 24 13.7042C24 12.0434 22.6361 10.6914 20.9608 10.6914C19.9503 10.6914 19.0047 11.1798 18.4241 11.9363Z"
            />
          </mask>
          <g mask="url(#StoryIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
StoryIcon.nessiePropTypes = iconNessiePropTypes;
StoryIcon = nessiePropCheckerWrapper(StoryIcon);
export default StoryIcon;
