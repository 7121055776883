import * as React from "react";
import { CloseIcon } from "../../nessie/icons";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";
import { translate } from "../../utils/translate";
import { UnstyledButton } from "../buttons";
import Video from "../misc/Video";
import BaseModal from "./Base";

type VideoSource = {
  path: string;
  contentType: string;
};

// we support either video sources or a single image source
export type ExpandedPhotoModalProps =
  | {
      sources: VideoSource[];
      poster?: string;
      close: React.ComponentPropsWithoutRef<typeof BaseModal>["onRequestHide"];
    }
  | {
      src?: string;
      close: React.ComponentPropsWithoutRef<typeof BaseModal>["onRequestHide"];
    };

const ExpandedPhotoModal = (props: ExpandedPhotoModalProps): JSX.Element => {
  let contents: React.ReactNode = null;

  if ("sources" in props) {
    contents = props.sources && (
      <Video
        data-name="expandedVideo"
        sources={props.sources}
        poster={props.poster}
        onClick={(e: React.MouseEvent) => e.stopPropagation()}
        preload="auto"
        containerStyle={{ height: "auto" }}
      />
    );
  }

  if ("src" in props && props.src && !contents) {
    contents = <div data-name="expandedPhotoImage" sx={{ ...styles.image, backgroundImage: `url(${props.src})` }} />;
  }

  return (
    <BaseModal
      data-name="expandedPhotoModal"
      sx={styles.modal}
      onRequestHide={props.close}
      requestHideOnOverlayClick={true}
      label={translate("dojo.common:portfolio.attachment")}
    >
      <>
        <UnstyledButton
          data-name="photo:close_dialog_x"
          onClick={props.close}
          aria-label={translate({ str: "dojo.common:actions.close_dialog", fallback: "Close dialog" })}
          sx={{
            position: "absolute",
            top: "dt_xl",
            left: "dt_xl",
            borderRadius: "dt_radius_m",
            backgroundColor: RAW_cssValue("rgba(26, 25, 45, 0.6)"), // this should be dt_content_media
            width: "54px",
            height: "54px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <CloseIcon size="m" color={"dt_content_light"} sx={{ margin: "auto" }} />
        </UnstyledButton>
        <UnstyledButton
          data-name="photo:close_dialog"
          onClick={props.close}
          aria-label={translate({ str: "dojo.common:actions.close_dialog", fallback: "Close dialog" })}
          sx={styles.verticalAlign}
        >
          {contents}
        </UnstyledButton>
      </>
    </BaseModal>
  );
};

export default ExpandedPhotoModal;

const styles: Record<string, ThemeUIStyleObject> = {
  modal: {
    outline: "none",
    border: "none",
    background: "transparent",
    boxShadow: "initial",
    position: "absolute" as const,
    top: "5%",
    left: "5%",
    width: "90%",
    height: "90%",
    transform: "none",
    WebkitTransform: "none",
    margin: 0,
  },

  verticalAlign: {
    position: "absolute" as const,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  image: {
    width: "100%",
    height: "100%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "50% 50%",
  },

  blocker: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
};
