import omit from "lodash/omit";
import { RAW_cssValue, ThemeUIStyleObject } from "../../stylingLib";

type CheckboxRadioInputProps = {
  groupName: string;
  groupType: "checkbox" | "radio";
  inputProps: CheckboxRadioInputsType;
  className?: string;
  inputId: string;
  inputDetailId?: string;
  disabled?: boolean;
  checked?: boolean;
};

export type CheckboxRadioInputsType = Omit<React.InputHTMLAttributes<HTMLInputElement>, "style"> & {
  labelText: string | React.ReactNode;
  detail?: string;
} & ConditionalTypes;

type ConditionalTypes =
  | {
      profileSrc: string;
      imgSrc?: never;
      isBlocked?: boolean;
    }
  | {
      profileSrc?: never;
      imgSrc: string;
      isBlocked?: never;
    }
  | {
      profileSrc?: never;
      imgSrc?: never;
      isBlocked?: never;
    };
/**
 * This component was created so it could be shared between web files that need a checkbox.
 * DO NOT USE IT OUTSIDE OF WEB AND NESSIE COMPONENTS
 * IF YOU CREATE A NEW COMPONENT WITH IT, MAKE SURE IT HAS ALL OF THE A11Y FEATURES
 */
function CheckboxRadioInput({
  groupType,
  groupName,
  inputProps,
  inputId,
  inputDetailId,
  className,
  disabled,
}: CheckboxRadioInputProps) {
  return (
    <div sx={inputRadioContainerStyles} className={className}>
      <input
        type={groupType}
        name={groupName}
        sx={invisibleInputStyles}
        {...omit(inputProps, ["labelText", "detail", "profileSrc", "imgSrc", "isBlocked", "profileAvatar"])}
        id={inputId}
        aria-describedby={inputDetailId}
        disabled={disabled}
        checked={inputProps.checked}
      />
      <div sx={inputMaskStyles} />
    </div>
  );
}

export default CheckboxRadioInput;

// eslint-disable-next-line react-refresh/only-export-components
export const RADIO_CHECKBOX_SIZE = 24;

const inputRadioContainerStyles: ThemeUIStyleObject = {
  position: "relative",
  width: "fit-content",
  height: "fit-content",
  "&:focus-within": {
    outline: "2px solid",
    outlineColor: "dt_border_active",
    outlineOffset: "2px",
  },
  input: {
    "&[type='radio'] + div": {
      borderRadius: "dt_radius_round",
      boxShadow: RAW_cssValue("inset 0px 0px 0px 3px rgba(255,255,255,1)"),
    },
    "&[type='checkbox'] + div": {
      borderRadius: "dt_radius_s",
      "&::after": {
        position: "absolute",
        left: "9px",
        top: "4px",
        width: "7px",
        height: "13px",
        border: RAW_cssValue("solid"),
        borderColor: "dt_background_primary",
        borderWidth: RAW_cssValue("0 3px 3px 0"),
        transform: "rotate(45deg)",
      },
    },
    "&:checked + div": {
      backgroundColor: "dt_content_accent",
      borderColor: "dt_border_active",
      "&::after": {
        content: '""',
      },
    },
    "&:disabled + div": {
      backgroundColor: "dt_background_disabled",
    },
  },
};

const inputMaskStyles: ThemeUIStyleObject = {
  border: "dt_functional",
  width: RADIO_CHECKBOX_SIZE,
  height: RADIO_CHECKBOX_SIZE,
  transition: "background-color 0.3s linear",
};

const invisibleInputStyles: ThemeUIStyleObject = {
  position: "absolute",
  opacity: 0,
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  zIndex: "2",
  cursor: "pointer",
  ":disabled": {
    cursor: "not-allowed",
    boxShadow: "none",
  },
};
