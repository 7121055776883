/* eslint-disable camelcase */
export const spacesArray = [0, 3, 6, 12, 18, 24, 30, 54];
export const nessieSpaces = {
  auto: "auto",
  unset: "unset",
  "0": spacesArray[0],
  xxs: spacesArray[1],
  xs: spacesArray[2], // baseline
  s: spacesArray[3],
  m: spacesArray[4],
  l: spacesArray[5],
  xl: spacesArray[6],
  xxl: spacesArray[7],
  // "dt_" aliases. We should eventually migrate all usages to these:
  dt_xxs: spacesArray[1],
  dt_xs: spacesArray[2], // baseline
  dt_s: spacesArray[3],
  dt_m: spacesArray[4],
  dt_l: spacesArray[5],
  dt_xl: spacesArray[6],
  dt_xxl: spacesArray[7],
};
const makeSpace = (spaces: [number, number, number, number, number, number, number, number]) => {
  return Object.assign(spaces, nessieSpaces);
};

//                        0 xxs xs s   m   l   xl  xxl
export const spaces = makeSpace([0, 3, 6, 12, 18, 24, 30, 54]);

const makeBreakpoints = (breakpoints: [string, string, string, string]) => {
  return Object.assign(breakpoints, {
    // Extra small screen / phone (mobile first)
    xs: 0,
    // Small screen / phone
    s: breakpoints[0],
    // Medium screen / tablet
    m: breakpoints[1],
    // Large screen / desktop
    l: breakpoints[2],
    // Extra large screen / wide desktop
    xl: breakpoints[3],

    // "dt_" aliases. We should eventually migrate all usages to these:
    dt_viewport_xs: 0,
    // Small screen / phone
    dt_viewport_s: breakpoints[0],
    // Medium screen / tablet
    dt_viewport_m: breakpoints[1],
    // Large screen / desktop
    dt_viewport_l: breakpoints[2],
    // Extra large screen / wide desktop
    dt_viewport_xl: breakpoints[3],
  });
};

export const breakpoints = makeBreakpoints(["576px", "768px", "992px", "1200px"]);

export type NessieSpaceSizes = keyof typeof nessieSpaces;

export const radius = {
  "0": "0",
  dt_radius_xs: "12px",
  dt_radius_s_inset: "16px",
  dt_radius_s: "18px",
  dt_radius_m: "30px",
  dt_radius_l: "48px",
  dt_radius_round: "50%",
  dt_radius_pill: "9999px",
} as const;

export type NessieThemeRadii = keyof typeof radius;

//
// Shadows
// theme-ui expects an array but we want 'keyed' tokens.
const makeShadows = (shadows: string[]) => {
  return Object.assign(shadows, shadowsObj);
};
const shadowsObj = {
  dt_shadow_shadezies: "0px 6px 0px rgba(45, 64, 150, 0.06)",
  dt_shadow_shadezies_inverted: "0px -2px 0px rgba(45, 64, 150, 0.06)",
  dt_shadow_shadezies_small: "0px 2px 0px rgba(45, 64, 150, 0.06)",
  none: "none",
  initial: "initial",
};

export const shadows = makeShadows(Object.values(shadowsObj));
export type NessieThemeShadows = keyof typeof shadowsObj;

export const fontFamily = "'Dojo Text', 'Helvetica Neue', Helvetica, Arial, sans-serif";

export const fontWeights = {
  dt_semiBold: 600,
  dt_bold: 700,
  dt_extraBold: 800,
};

// --------------------------------------------
// Z INDEXES
// @classdojo/web has the values below. We should find a better way to read from web, for now copy / paste works.
// We want our modals to show on top of the @classdojo/web modals, so we can embed new modals on top of older modals but
// not the other way around.
const webPackageZIndexes = {
  overlay: 110,
  dropdown: 120,
  modalOverlay: 130,
  globalErrorBanner: 140, // error banner should appear above everything else
};

export const zIndexes = {
  // We want ALL our modals to have the same z-index. The way Portals work is by adding a new portal at the end of the
  // body, this means that if we render one modal inside other the portals are going to be added ascending order and
  // elements with same z-index will stack based on the dom order.
  // This enables us to combine modals from current @classdojo/web package and this package as well as full / non fullscreen ones.
  modalOverlay: webPackageZIndexes.modalOverlay,
  globalErrorBanner: webPackageZIndexes.globalErrorBanner,
};
export const stackingOrder = {
  /**
   * Used for the Overlay and everything that's inside.
   */
  OVERLAY: webPackageZIndexes.modalOverlay,
  /**
   * Used as the default for the StackingContext.
   */
  STACKING_CONTEXT: 5,
};
