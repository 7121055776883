// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type FullscreenIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let FullscreenIcon: NessieFunctionComponent<FullscreenIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "FullscreenIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: FullscreenIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.6 5.25C5.85442 5.25 5.25 5.85442 5.25 6.6V8.0625C5.25 8.68382 4.74632 9.1875 4.125 9.1875C3.50368 9.1875 3 8.68382 3 8.0625V6.6C3 4.61177 4.61177 3 6.6 3H8.0625C8.68382 3 9.1875 3.50368 9.1875 4.125C9.1875 4.74632 8.68382 5.25 8.0625 5.25H6.6ZM14.8125 4.125C14.8125 3.50368 15.3162 3 15.9375 3H17.4C19.3882 3 21 4.61177 21 6.6V8.0625C21 8.68382 20.4963 9.1875 19.875 9.1875C19.2537 9.1875 18.75 8.68382 18.75 8.0625V6.6C18.75 5.85442 18.1456 5.25 17.4 5.25H15.9375C15.3162 5.25 14.8125 4.74632 14.8125 4.125ZM4.125 14.8125C4.74632 14.8125 5.25 15.3162 5.25 15.9375V17.4C5.25 18.1456 5.85442 18.75 6.6 18.75H8.0625C8.68382 18.75 9.1875 19.2537 9.1875 19.875C9.1875 20.4963 8.68382 21 8.0625 21H6.6C4.61177 21 3 19.3882 3 17.4V15.9375C3 15.3162 3.50368 14.8125 4.125 14.8125ZM19.875 14.8125C20.4963 14.8125 21 15.3162 21 15.9375V17.4C21 19.3882 19.3882 21 17.4 21H15.9375C15.3162 21 14.8125 20.4963 14.8125 19.875C14.8125 19.2537 15.3162 18.75 15.9375 18.75H17.4C18.1456 18.75 18.75 18.1456 18.75 17.4V15.9375C18.75 15.3162 19.2537 14.8125 19.875 14.8125Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
FullscreenIcon.nessiePropTypes = iconNessiePropTypes;
FullscreenIcon = nessiePropCheckerWrapper(FullscreenIcon);
export default FullscreenIcon;
