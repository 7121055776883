import { setLogEventFunction } from "./utils/logEvent";
import { setTranslateFunction } from "./utils/translate";

type Config = {
  translate: Parameters<typeof setTranslateFunction>[0];
  logEvent: Parameters<typeof setLogEventFunction>[0];
};

export default function init(config: Config): void {
  setTranslateFunction(config.translate);
  setLogEventFunction(config.logEvent);
}
