// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type PencilIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let PencilIcon: NessieFunctionComponent<PencilIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "PencilIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: PencilIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M18.1367 3.16249L20.8461 5.87061C20.9545 5.97894 21.0267 6.12337 20.9906 6.2678C20.9906 6.41224 20.9545 6.55667 20.8461 6.66499L20.81 6.7011L17.7548 9.7548L14.2666 6.26656L16.0775 4.46239L16.1136 4.42628L16.9806 3.55968L17.378 3.16249C17.5948 2.94584 17.9199 2.94584 18.1367 3.16249ZM12.7638 7.76375L4.98691 15.5115C4.95079 15.5476 4.87854 15.6199 4.87854 15.6921L3.03613 20.2778C2.96387 20.4945 3 20.7111 3.1445 20.8556C3.25288 20.9639 3.39738 21 3.54189 21C3.61414 21 3.68639 21 3.75864 20.9639L6.6487 19.8084L8.31048 19.1224C8.38273 19.0863 8.45498 19.0501 8.49111 19.014L16.2544 11.2544L12.7638 7.76375Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PencilIcon.nessiePropTypes = iconNessiePropTypes;
PencilIcon = nessiePropCheckerWrapper(PencilIcon);
export default PencilIcon;
