import { components } from "@classdojo/ts-api-types/api";
import * as React from "react";
import { DropdownMenu, MenuOptions } from "../../nessie";
import { ThemeUIStyleObject } from "../../nessie/stylingLib";
import { translate } from "../../utils/translate";
import { UnstyledButton } from "../buttons";
import TooltipTrigger from "../overlay/TooltipTrigger";
import CommentsDisabledTooltip from "./CommentsDisabledTooltip";
import { Image } from "../misc/Image";

export type PostToolbarProps = {
  context: React.ComponentPropsWithoutRef<typeof CommentsDisabledTooltip>["context"];
  viewerType: React.ComponentPropsWithoutRef<typeof CommentsDisabledTooltip>["viewerType"];
  contactName?: string;
  likeButton: components["schemas"]["LikeButton"];
  commentButton: components["schemas"]["CommentButton"];
  enableComments?: React.ComponentPropsWithoutRef<typeof CommentsDisabledTooltip>["enableComments"];
  messagePoster?: () => void;
  like?: () => void;
  unlike?: () => void;
  toggleComments?: () => void;
  canComment: boolean;
  edit?: MenuOptions["onClick"];
  delete?: MenuOptions["onClick"];
  shouldHideDropdownMenu?: boolean;
};

// eslint-disable-next-line react-refresh/only-export-components
const PostToolbar = ({
  context,
  viewerType,
  contactName,
  enableComments,
  messagePoster,
  likeButton,
  like,
  unlike,
  commentButton,
  toggleComments,
  canComment,
  edit,
  delete: deleteFn,
  shouldHideDropdownMenu,
}: PostToolbarProps): JSX.Element => {
  const menuOptions = getMenuOptions(edit, deleteFn, messagePoster, contactName);
  const likeConditional = likeButton !== "hidden" && like && unlike;
  const commentConditional = commentButton !== "hidden" && toggleComments;

  const likedByMe = likeButton === "liked";
  return (
    <div sx={toolbarStyles} data-name="postToolbar">
      {likeConditional ? (
        <UnstyledButton
          sx={toolbarButtonStyles}
          onClick={likedByMe ? unlike : like}
          type="button"
          aria-pressed={likedByMe}
          data-name="likeButton"
        >
          <Image
            src={likedByMe ? IMAGES.heartActive : IMAGES.heartInactive}
            sx={{ width: "24px", height: "24px" }}
            alt=""
          />
          {likedByMe
            ? translate({ str: "dojo.common:class_story.liked" })
            : translate({ str: "dojo.common:class_story.like" })}
        </UnstyledButton>
      ) : null}
      {commentConditional ? (
        <TooltipTrigger
          data-name="commentsDisabledTooltipTrigger"
          hide={canComment}
          tooltipContent={
            <CommentsDisabledTooltip
              context={context}
              viewerType={viewerType}
              contactName={contactName}
              enableComments={enableComments}
              messagePoster={messagePoster}
            />
          }
          tooltipPosition="top"
          tooltipMaxWidth="22rem"
          tooltipPadding="dt_m"
          tooltipBackgroundColor="dt_background_primary"
          tooltipTextColor="dt_content_primary"
        >
          {/* the correct thing here would be to use an onToggle methode, native to the details element.
            the problem is that jest does not call the function correctly using onToggle, so we need to use on click for the tests to pass */}
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <UnstyledButton
            sx={toolbarButtonStyles}
            onClick={canComment ? toggleComments : () => null}
            data-name={canComment ? "commentButton" : "disabledCommentButton"}
            aria-disabled={!canComment}
          >
            <Image
              src={canComment ? IMAGES.commentEnabled : IMAGES.commentDisabled}
              sx={{ width: "24px", height: "24px" }}
              alt=""
            />
            {translate({ str: "dojo.common:class_story.comment" })}
          </UnstyledButton>
        </TooltipTrigger>
      ) : null}
      {!shouldHideDropdownMenu && menuOptions.length ? (
        <div sx={{ marginLeft: "auto" }}>
          <DropdownMenu
            data-name="post_toolbar:dropdown_menu"
            label={translate({ str: "dojo.common:class_story.story_post", fallback: "Story post" })}
            trigger={<MenuTrigger />}
            options={menuOptions}
            align="end"
          />
        </div>
      ) : null}
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export default React.memo(PostToolbar);

function getMenuOptions(edit?: () => void, deleteFn?: () => void, messagePoster?: () => void, contactName?: string) {
  const menuOptions: MenuOptions[] = [];
  if (edit) {
    menuOptions.push({
      label: translate({ str: "dojo.common:class_story.edit_post_reduced", fallback: "Edit" }),
      onClick: edit,
      opensAModal: true,
      "data-name": "post_toolbar:edit_post_reduced",
    });
  }
  if (deleteFn) {
    menuOptions.push({
      label: translate({ str: "dojo.common:class_story.delete_post_reduced", fallback: "Delete" }),
      onClick: deleteFn,
      opensAModal: true,
      "data-name": "post_toolbar:delete_post_reduced",
    });
  }
  if (messagePoster) {
    menuOptions.push({
      label: translate({
        str: "dojo.common:class_story.message_teacher",
        subs: { teacherName: contactName || "" },
        fallback: "Message __teacherName__",
      }),
      onClick: () => messagePoster(),
      opensAModal: true,
      "data-name": "post_toolbar:message_teacher",
    });
  }
  return menuOptions;
}

// eslint-disable-next-line react-refresh/only-export-components
const MenuTrigger = (): JSX.Element => {
  return (
    <div data-name="postMenuButton" sx={toolbarButtonStyles}>
      <Image src={IMAGES.dots} width={"24px"} height={"24px"} alt="" />
    </div>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
const IMAGES = {
  heartActive: "components/icon/likes.png",
  heartInactive: "components/icon/likes_unfilled.png",
  commentEnabled: "components/story/comment-button-enabled.png",
  commentDisabled: "components/story/comment-button-disabled.png",
  dots: "components/story/3_dots.png",
};

const toolbarStyles: ThemeUIStyleObject = {
  marginTop: "dt_m",
  borderTop: "dt_divider",
  padding: "dt_m",
  display: "flex",
  gap: "dt_s",
  "&:empty": {
    marginTop: 0,
    borderTop: "none",
    padding: 0,
  },
};

const toolbarButtonStyles: ThemeUIStyleObject = {
  display: "inline-flex",
  alignItems: "center",
  gap: "dt_s",
  cursor: "pointer",
  border: `dt_divider`,
  borderRadius: "dt_radius_s",
  boxShadow: "dt_shadow_shadezies_small",
  lineHeight: 0,
  paddingY: "dt_s",
  paddingX: "dt_m",
  color: "dt_content_tertiary",
  userSelect: "none",
  "&:hover": {
    boxShadow: "dt_shadow_shadezies",
  },
  "&:active": {
    backgroundColor: "dt_background_secondary",
    boxShadow: "dt_shadow_shadezies_small",
  },
  '&[aria-pressed="true"]': {
    backgroundColor: "dt_background_secondary",
    boxShadow: "dt_shadow_shadezies_small",
  },
  '&:disabled, &[aria-disabled="true"]': {
    cursor: "default",
    color: "dt_content_disabled",
  },
};
