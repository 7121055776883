import { translate } from "./translate";
import i18next from "i18next";
import {
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInHours,
  differenceInDays,
  differenceInYears,
  format,
  millisecondsInMinute,
  millisecondsInSecond,
  millisecondsInHour,
  isSame,
  subDays,
} from "@web-monorepo/dates";

// l -- 09/04/1986
export function makeShortDate(time: Date, locale?: string): string {
  return format(time, { month: "numeric", day: "numeric", year: "numeric", locale });
}

/**
 * This function returns how many hours / days have passes from the
 * time passed to today.
 *
 * @param  {Moment/Date/String} time Time to calculate from.
 * @return {String}      A String representing the time from the param to today, expressed in hour (up to 23h) or days.
 */

/*
  We want to roughly match the table below
  so that times are consistent when we're using moment.fromNow

  Range Key Sample Output
  0 to 45 seconds              --- a few seconds ago (ignoring)
  45 to 90 seconds             --- a minute ago
  90 seconds to 45 minutes     --- 2 minutes ago - 45 minutes ago
  45 to 90 minutes             --- an hour ago
  90 minutes to 22 hours       --- 2 hours ago - 22 hours ago
  22 to 36 hours               --- a day ago
  36 hours to 25 days          --- 2 days ago - 25 days ago
  25 to 45 days                --- a month ago
  45 to 345 days               --- 2 months ago - 11 months ago
  345 to 545 days (1.5 years)  --- a year ago
  546 days+                    --- 2 years ago - 20 years ago
*/

export function fromToday(time: Date, _locale?: string): string {
  if (!time) throw new Error("Must pass in a time!");

  const now = new Date();
  const then = time;

  const diff = differenceInMilliseconds(now, then);

  if (diff < 90 * millisecondsInSecond) return translate({ str: "dojo.common:from_today_time.1m", fallback: "1m" });
  if (diff < 3 * millisecondsInMinute) return translate({ str: "dojo.common:from_today_time.2m", fallback: "2m" });
  if (diff < 45 * millisecondsInMinute) {
    const difference = differenceInMinutes(now, then);
    return translate({
      str: "dojo.common:from_today_time.difference_m",
      fallback: `${difference}m`,
      subs: {
        difference,
      },
    });
  }
  if (diff < 90 * millisecondsInMinute) return translate({ str: "dojo.common:from_today_time.1h", fallback: "1h" });
  if (diff < 120 * millisecondsInMinute) return translate({ str: "dojo.common:from_today_time.2h", fallback: "2h" });
  if (diff < 22 * millisecondsInHour) {
    const difference = differenceInHours(now, then);
    return translate({
      str: "dojo.common:from_today_time.difference_h",
      fallback: `${difference}h`,
      subs: {
        difference,
      },
    });
  }
  if (diff < 36 * millisecondsInHour) return translate({ str: "dojo.common:from_today_time.1d", fallback: "1d" });
  if (diff < 3 * millisecondsInHour * 24) return translate({ str: "dojo.common:from_today_time.2d", fallback: "2d" });
  if (diff < 345 * millisecondsInHour * 24) {
    const difference = differenceInDays(now, then);
    return translate({
      str: "dojo.common:from_today_time.difference_d",
      fallback: `${difference}d`,
      subs: {
        difference,
      },
    });
  }
  if (diff < 545 * millisecondsInHour * 24) return translate({ str: "dojo.common:from_today_time.1y", fallback: "1y" });
  if (diff < 3 * millisecondsInHour * 24 * 365) {
    return translate({ str: "dojo.common:from_today_time.2y", fallback: "2y" });
  } else {
    const difference = differenceInYears(now, then);
    return translate({
      str: "dojo.common:from_today_time.difference_y",
      fallback: `${difference}y`,
      subs: {
        difference,
      },
    });
  }
}

export function makeTimestampString(time: Date, locale?: string): string {
  if (!time) throw new Error("Must pass in a time!");

  const now = new Date();

  const sameYear = isSame(now, time, { mode: "year" });
  const today = isSame(now, time, { mode: "day" });
  if (today) {
    return format(time, { hour: "numeric", minute: "2-digit", locale });
  }

  const yesterday = isSame(subDays(now, 1), time, { mode: "day" });
  if (yesterday) {
    return translate("dojo.common:date_picker.yesterday", "Yesterday");
  }

  if (sameYear) {
    // Not exactly the same as "MMM Do" (includes year)
    // return then.format("MMM Do");
    return format(time, { month: "short", day: "numeric", year: "numeric", locale });
  }

  return format(time, { month: "numeric", day: "2-digit", year: "numeric", locale });
}
// https://primer.style/components/relative-time:
export function fromNow(date: Date, locale?: string, skipSentence?: boolean): string {
  const localeToUse = locale || i18next.language;
  const now = new Date();
  const diff = date.getTime() - now.getTime();
  const seconds = Math.round(diff / 1000);

  if (Math.abs(seconds) < 60) {
    return translate("dojo.common:from_now.just_now", "just now");
  }
  const minutes = Math.floor(seconds / 60);
  if (Math.abs(minutes) < 60) {
    return new Intl.RelativeTimeFormat(localeToUse, { numeric: "auto" }).format(minutes, "minute");
  }
  const hours = Math.round(seconds / 3600);
  if (Math.abs(hours) < 24) {
    return new Intl.RelativeTimeFormat(localeToUse, { numeric: "auto" }).format(hours, "hour");
  }
  const days = Math.round(hours / 24);
  if (Math.abs(days) < 28) {
    return new Intl.RelativeTimeFormat(localeToUse, { numeric: "auto" }).format(days, "day");
  }

  const dateString = format(date, {
    locale: localeToUse,
    month: "short",
    day: "2-digit",
    year: days >= 365 ? "numeric" : undefined,
  });
  if (skipSentence) return dateString;

  return translate("dojo.common:from_now.on", "on __date__", { date: dateString });
}

export const getMonths = (locale?: string): string[] =>
  new Array(12).fill(0).map((_, i) => format(new Date(2020, i, 1), { month: "long", locale }));
export const getMonthsShort = (locale?: string): string[] =>
  new Array(12).fill(0).map((_, i) => format(new Date(2020, i, 1), { month: "short", locale }));
export const getWeekdays = (locale?: string): string[] =>
  // Jan 5, 2020 is a Sunday:
  new Array(7).fill(0).map((_, i) => format(new Date(2020, 0, i + 5), { weekday: "long", locale }));
export const getWeekdaysShort = (locale?: string): string[] =>
  // Jan 5, 2020 is a Sunday:
  new Array(7).fill(0).map((_, i) => format(new Date(2020, 0, i + 5), { weekday: "short", locale }));

export const getFormattedLocalizedMoment = (date: Date, locale?: string): string => {
  const localeToUse = locale || i18next.language;
  const withinToday = isSame(date, new Date(), { mode: "day" });
  const withinYear = isSame(date, new Date(), { mode: "year" });
  let localizedFormattedMoment = "";
  if (withinToday) {
    localizedFormattedMoment = fromNow(date, localeToUse);
  } else if (withinYear) {
    localizedFormattedMoment = format(date, { month: "short", day: "numeric", locale: localeToUse });
  } else {
    localizedFormattedMoment = format(date, { month: "long", day: "numeric", year: "numeric", locale: localeToUse });
  }
  return localizedFormattedMoment;
};
