import BodyText from "../typography/bodyText";
import LabelText from "../typography/labelText";

type CheckboxRadioTextProps = {
  labelText: string | React.ReactNode;
  inputId: string;
  detailText?: string;
  detailId?: string;
  limitSingleLine?: boolean;
  maxWidth?: string;
};
/**
 * This component was created so it could be shared between web files that need a checkbox.
 * DO NOT USE IT OUTSIDE OF WEB AND NESSIE COMPONENTS
 */
function CheckboxRadioText({
  labelText,
  detailText,
  inputId,
  detailId,
  limitSingleLine,
  maxWidth,
}: CheckboxRadioTextProps) {
  return (
    <div sx={{ display: "flex", flexDirection: "column", gap: "xxs", flexGrow: 1, maxWidth }}>
      {labelText ? (
        <LabelText
          level={2}
          as="label"
          htmlFor={inputId}
          textTransform="onlyFirst"
          sx={{
            width: "fit-content",
            "a, a:visited": { color: "dt_content_accent" },
            cursor: "pointer",
          }}
        >
          {labelText}
        </LabelText>
      ) : null}
      {detailText ? (
        <BodyText
          level={2}
          id={detailId}
          kind="tertiary"
          sx={limitSingleLine ? { whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" } : {}}
          textTransform="onlyFirst"
          aria-hidden={true}
        >
          {detailText}
        </BodyText>
      ) : null}
    </div>
  );
}

export default CheckboxRadioText;
