// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type UndoIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let UndoIcon: NessieFunctionComponent<UndoIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "UndoIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: UndoIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M17.9291 13C17.4439 16.3923 14.5265 19 11 19C7.13401 19 4 15.866 4 12C4 8.13401 7.13401 5 11 5C12.6968 5 14.2503 5.60248 15.4621 6.60623C15.8874 6.95854 16.5178 6.89935 16.8701 6.47402C17.2224 6.0487 17.1632 5.41831 16.7379 5.066C15.1807 3.7761 13.1796 3 11 3C6.02944 3 2 7.02944 2 12C2 16.9706 6.02944 21 11 21C15.6326 21 19.4476 17.5 19.9451 13H21.9962C22.4089 13 22.6439 12.5282 22.3953 12.1988L19.3991 8.2288C19.199 7.96374 18.8009 7.96374 18.6009 8.2288L15.6047 12.1988C15.356 12.5282 15.5911 13 16.0038 13H17.9291Z"
          />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
UndoIcon.nessiePropTypes = iconNessiePropTypes;
UndoIcon = nessiePropCheckerWrapper(UndoIcon);
export default UndoIcon;
