export const isTesting = Config.nodeEnv === "test";
export const isTest = isTesting;
export const isDev = Config.nodeEnv === "development";
export const isProd = Config.nodeEnv === "production";
export const isProduction = isProd;
export const isServer = typeof window === "undefined";
export const isClient = !isServer;

const host = !isServer && window && window.location && window.location.hostname ? window.location.hostname : "";
export const isHomeSite = host.includes("home.classdojo") || host.includes("home3.classdojo");
