// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type MicrophoneIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let MicrophoneIcon: NessieFunctionComponent<MicrophoneIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "MicrophoneIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: MicrophoneIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.6401 17.0401H12.2801C14.3601 17.0401 16.0801 15.3201 16.0801 13.2401V5.88008C16.0801 3.80008 14.3601 2.08008 12.2801 2.08008H11.6401C9.56011 2.08008 7.84011 3.80008 7.84011 5.88008V13.2401C7.84011 15.3201 9.56011 17.0401 11.6401 17.0401ZM10.1601 18.4001H13.7601C15.2801 18.4001 16.5601 19.6401 16.4801 21.2001C16.4801 21.5601 16.2001 21.8801 15.8001 21.8801H8.04011C7.68011 21.8801 7.36011 21.6001 7.36011 21.2001C7.36011 19.6401 8.64011 18.4001 10.1601 18.4001ZM11.6401 3.48008C10.3201 3.48008 9.24011 4.56008 9.24011 5.88008V12.2801H14.6801V5.88008C14.6801 4.56008 13.6001 3.48008 12.2801 3.48008H11.6401Z"
          />
          <mask
            id="MicrophoneIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={7}
            y={2}
            width={10}
            height={20}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M11.6401 17.0401H12.2801C14.3601 17.0401 16.0801 15.3201 16.0801 13.2401V5.88008C16.0801 3.80008 14.3601 2.08008 12.2801 2.08008H11.6401C9.56011 2.08008 7.84011 3.80008 7.84011 5.88008V13.2401C7.84011 15.3201 9.56011 17.0401 11.6401 17.0401ZM10.1601 18.4001H13.7601C15.2801 18.4001 16.5601 19.6401 16.4801 21.2001C16.4801 21.5601 16.2001 21.8801 15.8001 21.8801H8.04011C7.68011 21.8801 7.36011 21.6001 7.36011 21.2001C7.36011 19.6401 8.64011 18.4001 10.1601 18.4001ZM11.6401 3.48008C10.3201 3.48008 9.24011 4.56008 9.24011 5.88008V12.2801H14.6801V5.88008C14.6801 4.56008 13.6001 3.48008 12.2801 3.48008H11.6401Z"
            />
          </mask>
          <g mask="url(#MicrophoneIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
MicrophoneIcon.nessiePropTypes = iconNessiePropTypes;
MicrophoneIcon = nessiePropCheckerWrapper(MicrophoneIcon);
export default MicrophoneIcon;
