import { TranslatedString } from "../pods/i18n/translate";
import { TranslatableProps } from "../propShapes/translatable";

export type TranslateFunction = {
  (str: string | TranslatableProps): TranslatedString;
  (str: string, subs: TranslatableProps["subs"]): TranslatedString;
  (str: string, fallback: TranslatableProps["fallback"]): TranslatedString;
  (str: string, fallback: TranslatableProps["fallback"], subs: TranslatableProps["subs"]): TranslatedString;
};

let doTranslate = function doTranslate(
  _str: string | TranslatableProps,
  _subsOrFallback?: TranslatableProps["subs"] | TranslatableProps["fallback"],
  _subs?: TranslatableProps["subs"],
): TranslatedString {
  throw new Error("You must initialize the component library with a `translate` function to translate text!");
};

/**
 *
 * Takes either multiple arguments or a translatable obj.
 * Translatable object accepts the following keys:
 *   {str, htmlStr, fallback, subs},
 *
 */
const defaultTranslate = function (
  str: string | TranslatableProps,
  subsOrFallback?: TranslatableProps["subs"] | TranslatableProps["fallback"],
  subs?: TranslatableProps["subs"],
): TranslatedString {
  return doTranslate(str, subsOrFallback, subs);
};

export const translate = defaultTranslate;

export function setTranslateFunction(fn: TranslateFunction): void {
  doTranslate = fn;
}
