import uniqueId from "lodash/uniqueId";
import * as React from "react";
import { UnstyledButton } from "../../components/buttons";
import ProfilePicture from "../../components/misc/ProfilePicture";
import { TextArea } from "../../nessie";
import { translate } from "../../utils/translate";
import { SendIcon } from "../icons";
import { TextAreaProps } from "./TextArea";

type CommentInputProps = {
  onSubmit: (value: string) => void;
  avatarURL?: string;
  className?: string;
  colorKey?: string;
} & Omit<TextAreaProps, "onSubmit">;

export type CommentInputRef = {
  focus: () => void;
};

export const CommentInput = ({ onSubmit, avatarURL, colorKey, className, ...props }: CommentInputProps) => {
  const [value, setValue] = React.useState("");

  const COMMENT_MAX_LENGTH = 1000;
  const [commentInputId] = React.useState(uniqueId("commentInputId"));

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode !== 13) return;
    if (e.shiftKey) return;

    e.preventDefault();

    const comment = value.trim();
    if (comment === "") return;

    handleOnClickSubmit();
  };
  const handleOnClickSubmit = () => {
    onSubmit(value);
    setValue("");
  };

  const isValueEmpty = !value.length;

  return (
    <form
      sx={{ display: "flex", width: "100%", gap: "s", alignItems: "center" }}
      data-name="submitCommentInputWrapper"
      className={className}
    >
      <ProfilePicture src={avatarURL} size="4.4rem" sx={{ flexShrink: 0 }} colorKey={colorKey} />
      <TextArea
        maxLength={COMMENT_MAX_LENGTH}
        aria-label={translate({ str: "dojo.common:portfolio.write_comment", fallback: "Write a comment" })}
        value={value}
        id={commentInputId}
        onChange={(newValue) => setValue(newValue)}
        onKeyDown={handleKeyDown}
        data-name="submitCommentInput"
        sx={{ flexGrow: 1, textarea: { paddingRight: "xxl" } }}
        {...props}
      >
        <UnstyledButton
          aria-label={translate({ str: "dojo.common:portfolio.send_comment", fallback: "Send comment" })}
          onClick={handleOnClickSubmit}
          sx={{
            position: "absolute",
            height: "100%",
            top: "0",
            right: "22px",
            display: "flex",
            alignItems: "center",
          }}
          data-name="submitCommentInputButton"
          disabled={value.trim() === ""}
          type="submit"
        >
          <SendIcon color={isValueEmpty ? "dt_content_disabled" : "dt_content_accent"} />
        </UnstyledButton>
      </TextArea>
    </form>
  );
};
