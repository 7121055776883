// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type PaintBrushIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let PaintBrushIcon: NessieFunctionComponent<PaintBrushIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "PaintBrushIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: PaintBrushIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9611 10.699L20.7399 1.45642C21.1968 0.913566 22.0047 0.845556 22.545 1.30293C23.0863 1.76093 23.1541 2.57122 22.6975 3.11376L14.9196 12.3563C15.0458 12.9632 14.9034 13.6194 14.474 14.1297L13.783 15.0803C13.2619 13.7195 12.2821 12.855 11.282 12.3682C11.1208 12.2879 10.9595 12.2172 10.7988 12.1531L11.2931 11.4366C11.7216 10.9263 12.3444 10.676 12.9611 10.699ZM9.40207 13.5602C9.66231 13.6448 9.92713 13.7483 10.1861 13.8785C11.1507 14.3745 12.0344 15.1554 12.3948 16.6005L12.3493 16.6544C12.1416 21.2827 7.09658 23.0999 4.26267 22.9958C1.40096 22.8904 0.739063 20.5545 1.08299 20.5254C1.15542 20.5193 1.25761 20.5237 1.38199 20.529C2.27739 20.5676 4.32257 20.6556 4.68785 17.222C4.98107 14.4594 8.50895 13.6641 9.40207 13.5602Z"
          />
          <mask
            id="PaintBrushIcon_svg__mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x={1}
            y={1}
            width={22}
            height={22}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.9611 10.699L20.7399 1.45642C21.1968 0.913566 22.0047 0.845556 22.545 1.30293C23.0863 1.76093 23.1541 2.57122 22.6975 3.11376L14.9196 12.3563C15.0458 12.9632 14.9034 13.6194 14.474 14.1297L13.783 15.0803C13.2619 13.7195 12.2821 12.855 11.282 12.3682C11.1208 12.2879 10.9595 12.2172 10.7988 12.1531L11.2931 11.4366C11.7216 10.9263 12.3444 10.676 12.9611 10.699ZM9.40207 13.5602C9.66231 13.6448 9.92713 13.7483 10.1861 13.8785C11.1507 14.3745 12.0344 15.1554 12.3948 16.6005L12.3493 16.6544C12.1416 21.2827 7.09658 23.0999 4.26267 22.9958C1.40096 22.8904 0.739063 20.5545 1.08299 20.5254C1.15542 20.5193 1.25761 20.5237 1.38199 20.529C2.27739 20.5676 4.32257 20.6556 4.68785 17.222C4.98107 14.4594 8.50895 13.6641 9.40207 13.5602Z"
            />
          </mask>
          <g mask="url(#PaintBrushIcon_svg__mask0)" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
PaintBrushIcon.nessiePropTypes = iconNessiePropTypes;
PaintBrushIcon = nessiePropCheckerWrapper(PaintBrushIcon);
export default PaintBrushIcon;
