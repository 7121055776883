import * as React from "react";
import LabelText from "../../nessie/components/typography/labelText";
import { RAW_cssValue, ThemeUIStyleObject } from "../../nessie/stylingLib";

type ButtonProps = {
  onClick?: React.MouseEventHandler;
  onBlur?: React.FocusEventHandler;
  disabled?: boolean;
  width?: number | string;
  kind?: "primary" | "secondary" | "tertiary" | "destructive" | "like" | "plus" | "link" | "ghost";
  icon?: React.ReactNode;
  size?: "s" | "m" | "l";
  /**
   * The name will get used for automated product events.
   * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
   */
  "data-name": string;
  "data-event-metadata"?: string;
  "data-experiments"?: string[];
  "aria-label"?: string;
  "aria-describedby"?: string;
  type?: "submit" | "button" | "reset";
  href?: string;
  target?: string;
  className?: string;
  id?: string;
  tabIndex?: number;
  "aria-controls"?: string;
  "aria-expanded"?: boolean;
  // Allow for `aria-disabled` instead of `disabled` for better a11y,
  //as it allows for the button to have focus and trigger validations, etc. when appropriate
  "aria-disabled"?: boolean;
  title?: string;

  // we saw a bug where the blur event interrupted the click event on a button this allows
  // for a better control for the input on forms
  preventBlurEvent?: boolean;
};

// we want buttons to have the same height whether they have icons or no
const getPadding = (size: string, hasIcon: boolean) => {
  if (size === "l") return hasIcon ? RAW_cssValue("18px 24px") : RAW_cssValue("19px 24px");
  if (size === "m") return hasIcon ? RAW_cssValue("12px 18px") : RAW_cssValue("13px 18px");
  return RAW_cssValue("12px 18px");
};

export const Button = ({
  onClick,
  onBlur,
  disabled,
  width,
  kind = "primary",
  icon,
  children,
  size = "l",
  ["data-name"]: dataName,
  ["data-event-metadata"]: dataMetadata,
  ["data-experiments"]: dataExperiments,
  ["aria-label"]: ariaLabel,
  ["aria-describedby"]: ariaDescribedby,
  ["aria-controls"]: ariaControls,
  ["aria-expanded"]: ariaExpanded,
  ["aria-disabled"]: ariaDisabled,
  id,
  type = "button",
  href,
  className,
  target,
  tabIndex,
  title,
  preventBlurEvent = false,
}: React.PropsWithChildren<ButtonProps>): JSX.Element => {
  const Element = href ? "a" : "button";
  return (
    <Element
      sx={{
        width,
        border: "none",
        position: "relative",
        backgroundColor: "transparent",
        padding: getPadding(size, !!icon),
        ...buttonStyle,
        ...getButtonColorStyle(kind, disabled, ariaDisabled),
      }}
      onMouseDown={(e) => {
        if (preventBlurEvent) {
          e.preventDefault();
        }
      }}
      disabled={disabled}
      onClick={!disabled ? onClick : undefined}
      onBlur={onBlur}
      className={className}
      data-name={dataName}
      data-event-metadata={dataMetadata}
      data-experiments={dataExperiments}
      aria-label={ariaLabel}
      aria-describedby={ariaDescribedby}
      type={href ? undefined : type}
      target={target}
      href={href}
      tabIndex={tabIndex}
      aria-controls={ariaControls}
      aria-expanded={ariaExpanded}
      aria-disabled={ariaDisabled}
      id={id}
      title={title}
    >
      {icon && React.isValidElement<{ size: string }>(icon) ? (
        <div sx={{ marginRight: children ? "dt_xs" : "0", position: "relative" }}>
          {/* default icon size="s" if button size="s" */}
          {size === "s" && !icon.props.size ? React.cloneElement(icon, { size: "s" }) : icon}
        </div>
      ) : null}
      <LabelText level={size === "s" ? 2 : 1}>{children}</LabelText>
    </Element>
  );
};

const spanStyles: ThemeUIStyleObject = {
  color: "dt_content_light",
  whiteSpace: "nowrap",
};

const buttonStyle: ThemeUIStyleObject = {
  borderRadius: "dt_radius_pill",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  fontWeight: "bold",
  span: spanStyles,
  svg: {
    verticalAlign: "middle",
  },
};

const primaryStyle: ThemeUIStyleObject = {
  backgroundColor: "dt_content_accent",
  ":hover, :focus": { backgroundColor: "dt_content_accent", opacity: 0.9 },
  ":active": { backgroundColor: "dt_content_accent", opacity: 0.8 },
};

const secondaryStyle: ThemeUIStyleObject = {
  backgroundColor: "dt_background_tertiary",
  span: { ...spanStyles, color: "dt_content_primary" },
  "svg path": { fill: "dt_content_primary" },
  ":hover, :focus": {
    backgroundColor: "dt_background_tertiary",
    opacity: 0.8,
    span: { ...spanStyles, color: "dt_content_primary" },
    "svg path": { fill: "dt_content_primary" },
  },
  ":active": {
    backgroundColor: "dt_background_tertiary",
    span: { ...spanStyles, color: "dt_content_tertiary" },
    "svg path": { fill: "dt_content_tertiary" },
  },
};

const tertiaryStyle: ThemeUIStyleObject = {
  backgroundColor: "dt_background_primary",
  span: { ...spanStyles, color: "dt_content_accent" },
  "svg path": { fill: "dt_content_accent" },
  ":hover, :focus": {
    backgroundColor: "dt_background_accent",
    span: { ...spanStyles, color: "dt_content_accent" },
    "svg path": { fill: "dt_content_accent" },
  },
  ":active": {
    backgroundColor: "dt_background_accent",
    span: { ...spanStyles, color: "dt_content_accent" },
    "svg path": { fill: "dt_content_accent" },
  },
};

const plusStyle: ThemeUIStyleObject = {
  backgroundColor: "dt_content_plus",
  ":hover, :focus": { backgroundColor: "dt_content_plus", opacity: 0.9 },
  ":active": { backgroundColor: "dt_content_plus" },
};

const destructiveStyle: ThemeUIStyleObject = {
  backgroundColor: "dt_content_danger",
  span: { ...spanStyles, color: "dt_content_light" },
  ":hover, :focus": { backgroundColor: "dt_content_danger", opacity: 0.9 },
  ":active": { backgroundColor: "dt_content_danger", opacity: 0.8 },
};

const likeActionStyle: ThemeUIStyleObject = {
  backgroundColor: "dt_background_danger",
  span: { ...spanStyles, color: "dt_content_danger" },
  "svg path": { fill: "dt_content_danger" },
};

const linkStyle: ThemeUIStyleObject = {
  span: { ...spanStyles, color: "dt_content_accent" },
  "svg path": { fill: "dt_content_accent" },
  ":hover, :focus": {
    span: { ...spanStyles, color: "dt_content_accent", opacity: 0.9 },
    "svg path": { fill: "dt_content_accent", opacity: 0.9 },
  },
  ":active": {
    span: { ...spanStyles, color: "dt_content_accent", opacity: 0.9 },
    "svg path": { fill: "dt_content_accent", opacity: 0.9 },
  },
};

const ghostStyle: ThemeUIStyleObject = {
  backgroundColor: "transparent",
  span: { ...spanStyles, color: "dt_content_accent" },
  "svg path": { fill: "dt_content_accent" },
  ":hover, :focus": {
    span: { ...spanStyles, color: "dt_content_accent", opacity: 0.9 },
    "svg path": { fill: "dt_content_accent", opacity: 0.9 },
  },
  ":active": {
    span: { ...spanStyles, color: "dt_content_accent", opacity: 0.9 },
    "svg path": { fill: "dt_content_accent", opacity: 0.9 },
  },
  padding: "0",
};

const disabledStyle: ThemeUIStyleObject = {
  cursor: "not-allowed",
  backgroundColor: "dt_background_secondary",
  span: { ...spanStyles, color: "dt_content_tertiary" },
  "svg path": { fill: "dt_content_tertiary" },
  ":hover, :focus": {
    backgroundColor: "dt_background_secondary",
    span: { ...spanStyles, color: "dt_content_tertiary" },
    "svg path": { fill: "dt_content_tertiary" },
  },
  ":active": {
    backgroundColor: "dt_background_secondary",
    span: { ...spanStyles, color: "dt_content_tertiary" },
    "svg path": { fill: "dt_content_tertiary" },
  },
};

const disabledTertiaryStyle: ThemeUIStyleObject = {
  backgroundColor: "dt_background_primary",
  span: { ...spanStyles, color: "dt_content_tertiary" },
  "svg path": { fill: "dt_content_tertiary" },
  ":hover": {
    backgroundColor: "dt_background_primary",
    span: { ...spanStyles, color: "dt_content_tertiary" },
    "svg path": { fill: "dt_content_tertiary" },
  },
  ":active, :focus": {
    backgroundColor: "dt_background_primary",
    span: { ...spanStyles, color: "dt_content_tertiary" },
    "svg path": { fill: "dt_content_tertiary" },
  },
};

const stylesByKind: Record<NonNullable<ButtonProps["kind"]>, ThemeUIStyleObject> = {
  primary: primaryStyle,
  secondary: secondaryStyle,
  tertiary: tertiaryStyle,
  destructive: destructiveStyle,
  plus: plusStyle,
  like: likeActionStyle,
  link: linkStyle,
  ghost: ghostStyle,
};

const getButtonColorStyle = (
  kind: NonNullable<ButtonProps["kind"]>,
  disabled: ButtonProps["disabled"],
  ariaDisabled: ButtonProps["aria-disabled"],
) => {
  // Only style based on the `aria-disabled` state if there is no `disabled` state
  const useDisabledStyle = disabled || (disabled == null && ariaDisabled);
  return {
    ...(stylesByKind[kind] || {}),
    ...((useDisabledStyle && disabledStyle) || {}),
    ...((useDisabledStyle && kind === "tertiary" && disabledTertiaryStyle) || {}),
  };
};
