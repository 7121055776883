// DO NOT CHANGE THIS FILE MANUALLY.
// It was generated using iconTemplate.js Please refer to web/src/nessie/icons/README.md for more details
import * as React from "react";
import UnstyledButton from "../../../../components/buttons/UnstyledButton";
import { nessiePropCheckerWrapper } from "../../nessiePropCheckerWrapper";
import { NessieFunctionComponent, NessieComponentProps, iconNessiePropTypes, NessieIconSizes } from "../../propTypes";
import { NessieThemeColors } from "../../theme";
type FullscreenExitIconProps = React.SVGProps<SVGSVGElement> &
  NessieComponentProps & {
    size?: NessieIconSizes;
    color?: NessieThemeColors;
    onClick?: () => void;
    cursor?: React.CSSProperties["cursor"];
    display?: React.CSSProperties["display"];
    /**
     * The name will get used for automated product events.
     * @see https://www.notion.so/classdojo/Automatic-Product-Events-for-Web-bfc580f10a914c3ba514e5ec20f8ef9e?pvs=4
     */
    "data-name"?: string;
    "aria-label"?: string;
  };
let FullscreenExitIcon: NessieFunctionComponent<FullscreenExitIconProps> = ({
  size = "m",
  color = "dt_content_primary",
  onClick,
  cursor,
  display = "inline-block",
  ["data-name"]: dataName = "FullscreenExitIcon",
  ["aria-label"]: ariaLabel,
  ...props
}: FullscreenExitIconProps) => {
  const sizeInPixels = SIZES[size];
  const ButtonOrSpan = onClick ? UnstyledButton : "span";
  return (
    <ButtonOrSpan
      aria-label={ariaLabel}
      data-name={dataName}
      onClick={onClick}
      sx={{
        fontSize: sizeInPixels,
        display,
        lineHeight: 0,
        color,
        cursor,
        position: "relative",
      }}
    >
      {
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 24 24"
          fill="currentColor"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <path d="M6.8 8.00001C7.46274 8.00001 8 7.46275 8 6.80001V5.50001C8 4.94772 8.44771 4.50001 9 4.50001C9.55228 4.50001 10 4.94772 10 5.50001L10 6.80001C10 8.56732 8.56731 10 6.8 10L5.5 10C4.94771 10 4.5 9.55229 4.5 9.00001C4.5 8.44772 4.94771 8.00001 5.5 8.00001H6.8Z" />
          <path d="M17.2 16C16.5373 16 16 16.5373 16 17.2V18.5C16 19.0523 15.5523 19.5 15 19.5C14.4477 19.5 14 19.0523 14 18.5V17.2C14 15.4327 15.4327 14 17.2 14H18.5C19.0523 14 19.5 14.4477 19.5 15C19.5 15.5523 19.0523 16 18.5 16H17.2Z" />
          <path d="M16 6.8C16 7.46274 16.5373 8 17.2 8H18.5C19.0523 8 19.5 8.44771 19.5 9C19.5 9.55228 19.0523 10 18.5 10H17.2C15.4327 10 14 8.56731 14 6.8V5.5C14 4.94772 14.4477 4.5 15 4.5C15.5523 4.5 16 4.94772 16 5.5V6.8Z" />
          <path d="M8 17.2C8 16.5373 7.46274 16 6.8 16H5.5C4.94772 16 4.5 15.5523 4.5 15C4.5 14.4477 4.94772 14 5.5 14H6.8C8.56731 14 10 15.4327 10 17.2L10 18.5C10 19.0523 9.55229 19.5 9 19.5C8.44772 19.5 8 19.0523 8 18.5L8 17.2Z" />
        </svg>
      }
    </ButtonOrSpan>
  );
};
const SIZES = {
  xs: "12px",
  s: "18px",
  m: "24px",
  l: "36px",
  xl: "48px",
  xxl: "56px",
};
FullscreenExitIcon.nessiePropTypes = iconNessiePropTypes;
FullscreenExitIcon = nessiePropCheckerWrapper(FullscreenExitIcon);
export default FullscreenExitIcon;
